<template>
  <div>
    <Title title="Student take-home work" />
    <HomeworkSheetList :homeworkListItems="homeworkListItems" />
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import HomeworkSheetList from '@/components/HomeworkSheetList.vue'

export default {
  components: {
    Title,
    HomeworkSheetList
  },
  data () {
    return {
      homeworkListItems: [
        { homeworkName: 'Flight Administration Checklist', fileName: 'flight-administration-checklist', imagePath: require('@/assets/images/student_homework_sheets/flight-administration-checklist_page1.pdf-150.png') },
        { homeworkName: 'Effect of Controls', fileName: 'effect-of-controls', imagePath: require('@/assets/images/student_homework_sheets/effect-of-controls_page1.pdf-150.png') },
        { homeworkName: 'Straight and Level', fileName: 'straight-and-level', imagePath: require('@/assets/images/student_homework_sheets/straight-and-level_page1.pdf-150.png') },
        { homeworkName: 'Climbing and Descending', fileName: 'climbing-and-descending', imagePath: require('@/assets/images/student_homework_sheets/climbing-and-descending_page1.pdf-150.png') },
        { homeworkName: 'Turning', fileName: 'turning', imagePath: require('@/assets/images/student_homework_sheets/turning_page1.pdf-150.png') },
        { homeworkName: 'Stalling', fileName: 'stalling', imagePath: require('@/assets/images/student_homework_sheets/stalling_page1.pdf-150.png') },
        { homeworkName: 'Circuits', fileName: 'circuits', imagePath: require('@/assets/images/student_homework_sheets/circuits_page1.pdf-150.png') },
        { homeworkName: 'Circuit Emergencies', fileName: 'circuit-emergencies', imagePath: require('@/assets/images/student_homework_sheets/circuit-emergencies_page1.pdf-150.png') },
        { homeworkName: 'Crosswind Circuits', fileName: 'circuits-crosswind', imagePath: require('@/assets/images/student_homework_sheets/circuits-crosswind_page1.pdf-150.png') },
        { homeworkName: 'Circuit Solo', fileName: 'circuit-solo', imagePath: require('@/assets/images/student_homework_sheets/circuit-solo_page1.pdf-150.png') },
        { homeworkName: 'Forced Landings', fileName: 'forced-landings', imagePath: require('@/assets/images/student_homework_sheets/forced-landings_page1.pdf-150.png') },
        { homeworkName: 'Precautionary Search & Landing', fileName: 'precautionary-search-and-landing', imagePath: require('@/assets/images/student_homework_sheets/precautionary-search-and-landing_page1.pdf-150.png') },
        { homeworkName: 'Steep Turns', fileName: 'steep-turns', imagePath: require('@/assets/images/student_homework_sheets/steep-turns_page1.pdf-150.png') },
        { homeworkName: 'Maintenance Release', fileName: 'maintenance-release', imagePath: require('@/assets/images/student_homework_sheets/maintenance-release_page1.pdf-150.png') },
        { homeworkName: 'Archerfield Operations', fileName: 'archerfield-operations', imagePath: require('@/assets/images/student_homework_sheets/archerfield-operations_page1.pdf-150.png') },
        { homeworkName: 'Area Solo', fileName: 'area-solo', imagePath: require('@/assets/images/student_homework_sheets/area-solo_page1.pdf-150.png') },
        { homeworkName: 'Eurofox Weight and Balance', fileName: 'eurofox-weight-and-balance', imagePath: require('@/assets/images/student_homework_sheets/eurofox-weight-and-balance_page1.pdf-150.png') },
        { homeworkName: 'Cessna 172R Weight and Balance', fileName: 'c172r-weight-and-balance', imagePath: require('@/assets/images/student_homework_sheets/c172r-weight-and-balance_page1.pdf-150.png') },
        { homeworkName: 'RPC exam preparation', fileName: 'rpc-exam-preparation', imagePath: require('@/assets/images/student_homework_sheets/rpc-exam-preparation_page1.pdf-150.png') },
        { homeworkName: 'RPC exam preparation answers', fileName: 'rpc-exam-preparation-answers', imagePath: require('@/assets/images/student_homework_sheets/rpc-exam-preparation-answers_page1.pdf-150.png') },
        { homeworkName: 'Passenger Endorsement', fileName: 'pax-endorsement', imagePath: require('@/assets/images/student_homework_sheets/pax-endorsement_page1.pdf-150.png') },
        { homeworkName: 'Navigation Preparation', fileName: 'nav-preparation', imagePath: require('@/assets/images/student_homework_sheets/nav-preparation_page1.pdf-150.png') },
        { homeworkName: 'Whiz Wheel Exercises', fileName: 'whiz-wheel-exercises', imagePath: require('@/assets/images/student_homework_sheets/whiz-wheel-exercises_page1.pdf-150.png') },
        { homeworkName: 'RPC Meteorology', fileName: 'rpc-meteorology', imagePath: require('@/assets/images/student_homework_sheets/rpc-meteorology_page1.pdf-150.png') }
      ]
    }
  }
}
</script>
