<template>
  <div>
    <ul class="sheet-list">
      <li class="list-item" v-for="(item, index) in homeworkListItems" :key="index">
        <img class="sheet-image" :src="item.imagePath" :alt="item.homeworkName" />
        <span>{{ item.homeworkName }}</span>
        <div class="sheet-links">
          <a :href="'https://flightscope.gitlab.io/training/student-homework-sheets/' + item.fileName + '.pdf'">pdf</a>
          <a :href="'https://flightscope.gitlab.io/training/student-homework-sheets/' + item.fileName + '.odt'">odt</a>
          <a :href="'https://flightscope.gitlab.io/training/student-homework-sheets/' + item.fileName + '.html'">html</a>
          <a :href="'https://flightscope.gitlab.io/training/student-homework-sheets/' + item.fileName + '.docx'">docx</a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['homeworkListItems']
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.sheet-list {
  width: 80vw;
  margin: auto;
  padding: 0;
}

.list-item {
  list-style-type: none;
  border: 1px solid $primary;
  break-inside: avoid-column;
  margin-left: -1px;
  margin-top: -1px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;

  span {
    @extend h2;
  }
}

.sheet-links {
  display: flex;
  flex-direction: column;
}

.sheet-image {
  width: 5em;
}
</style>
